export default [
  {
    text: "(GMT+00:00) UTC",
    value: 0,
  },
  {
    text: "(GMT+02:00) Калининград",
    value: 7200,
  },
  {
    text: "(GMT+03:00) Москва",
    value: 10800,
  },
  {
    text: "(GMT+04:00) Самара",
    value: 14400,
  },
  {
    text: "(GMT+05:00) Перьм",
    value: 18000,
  },
  {
    text: "(GMT+06:00) Омск",
    value: 21600,
  },
  {
    text: "(GMT+07:00) Красноярск, Новосибирск",
    value: 25200,
  },
  {
    text: "(GMT+08:00) Иркутск",
    value: 28800,
  },
  {
    text: "(GMT+09:00) Чита",
    value: 32400,
  },
  {
    text: "(GMT+10:00) Владивосток",
    value: 36000,
  },
  {
    text: "(GMT+11:00) Магадан, Южно-Сахалинск",
    value: 39600,
  },
  {
    text: "(GMT+12:00) Анадырь",
    value: 43200,
  },
];
